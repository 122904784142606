import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../../actions/setLoadingStatus';

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';

export type FetchCurrenciesAction = {
  type: string;
  currencies: Array<string>;
};

const fetchCurrencies = (currencies: Array<string>): FetchCurrenciesAction => {
  return {
    type: FETCH_CURRENCIES,
    currencies
  };
};

const fetchCurrenciesAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/auth/currency`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((currencies: any) => dispatch(fetchCurrencies(currencies)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchCurrenciesAsync;
