export const areAllPropsEQFalse = (object: Record<string, boolean>): boolean => {
  return Object.keys(object).every((property) => object[property] === false);
};

export const convertAllPropsToTrue = (object: Record<string, boolean>): Record<string, boolean> => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  
  Object.keys(objectCopy).forEach((property) => {
    objectCopy[property] = true;
  });

  return objectCopy;
};