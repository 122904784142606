import { InvestorOrder } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';
import { FETCH_MASTER_INVESTORS_ORDERS, FetchMasterInvestorsOrdersAction } from '../../actions/master/fetchMasterInvestorsOrders';

export interface MasterInvestorsOrdersState {
  orders: Array<InvestorOrder>;
  paginationInfo: PaginationInfo;
}

const initialState: MasterInvestorsOrdersState = {
  orders: [] as Array<InvestorOrder>,
  paginationInfo: {} as PaginationInfo
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const masterInvestorsOrdersReducer = (state: MasterInvestorsOrdersState = initialState, action: FetchMasterInvestorsOrdersAction): MasterInvestorsOrdersState => {
  switch (action.type) {
    case FETCH_MASTER_INVESTORS_ORDERS:
      return {
        ...state,
        orders: action.orders,
        paginationInfo: action.paginationInfo
      }
    default:
      return state;
  }
}

export default masterInvestorsOrdersReducer;