import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { AdminSettings } from '../../../types/settingsTypes';
import setLoadingStatus from '../../actions/setLoadingStatus';

export const FETCH_ADMIN_SETTINGS = 'FETCH_ADMIN_SETTINGS';

export type FetchAdminSettingsAction = {
  type: string;
  settings: AdminSettings;
};

const fetchAdminSettings = (settings: AdminSettings): FetchAdminSettingsAction => {
  return {
    type: FETCH_ADMIN_SETTINGS,
    settings
  };
};

const fetchAdminSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/account/admin`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => dispatch(fetchAdminSettings(settings)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const editAdminSettingsAsync = (api: string, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/account/admin`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => {
      dispatch(fetchAdminSettings(settings));
      toast.success('Admins settings edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchAdminSettingsAsync;
