import {
  LOGIN,
  LOGOUT,
  AuthAction,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_RESET_STATE
} from '../actions/auth';
// import LocalStorageUtils from '../../utils/local-storage';
import { User } from '../../types/authTypes';

export interface AuthState {
  isAuthenticated: boolean;
  user: User;
  isLoading: boolean;
  success: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: {} as User,
  isLoading: false,
  success: false
};

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {} as User
      };

    case LOGIN_START:
      return {
        ...state,
        isLoading: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true
      };

    case LOGIN_RESET_STATE:
      return {
        ...state,
        isLoading: false,
        success: false
      };

    default:
      return state;
  }
};

export default authReducer;
