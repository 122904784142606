/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector } from 'react-redux';
import { PAMMState } from '../../redux/reducers/rootReducer';
import englishIcon from '../../icons/English.svg';
import deutschIcon from '../../icons/Deutsch.svg';
import frenchIcon from '../../icons/French.svg';
import portugalIcon from '../../icons/Portugal.svg';
import spanishIcon from '../../icons/Spanish.svg';
import russianIcon from '../../icons/Russian.svg';
import arabianIcon from '../../icons/Arabian.svg';
import japanIcon from '../../icons/Japan.svg';
import koreanIcon from '../../icons/Korean.svg';
import georgiaIcon from '../../icons/Georgia.svg';
import chineseIcon from '../../icons/Chinese.svg';

const LanguagesMenuMobile: React.FC<any> = ({
  menuHeight = 0,
  onSwipe,
  onCancel,
  onLangChange
}): JSX.Element => {
  const languages: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.languages);

  const getLanguagesFlags = (value: any): string => {
    
    switch (value) {
      case 'English':
        return englishIcon;
      case 'Deutsch':
        return deutschIcon;
      case 'Français':
        return frenchIcon;
      case 'Português':
        return portugalIcon;
      case 'Español':
        return spanishIcon;
      case 'ქართული':
        return georgiaIcon;
      case 'العربية':
        return arabianIcon;
      case '한국어':
        return koreanIcon;
      case 'Русский':
        return russianIcon;
      case '日本語':
        return japanIcon;
      case '中文':
        return chineseIcon;
      default:
        return '';
    }
  };

  const langLinks = Object.keys(languages).map((lang: string, index: number) => (
    <div key={index} onClick={() => onLangChange(lang)}>
      <div className="row mb-3 align-items-center">
        <span className="ml-3 d-flex align-items-center" style={{marginBottom: 2}}>
          <img
            src={getLanguagesFlags(languages[lang])}
            className="mr-2"
            height="20px"
            width="20px"
            alt="no flag"
          />
          <span style={{marginTop: 2}}>
            {languages[lang]}
          </span>
        </span>
      </div>

      {index === Object.keys(languages).length
      ? <hr />
      : null}
    </div>
  ));

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties; 

  return (
    <div
      id="languages-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('languagesMenu')}
    >
      {langLinks}
    </div>
  );
};

export default LanguagesMenuMobile;
