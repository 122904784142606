import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
// import { UpdateMaster } from '../../../types/masterTypes';
import fetchMasterDetailsAsync from './fetchMasterDetails';

function updateMaster(api: string, masterId: string, request: any) {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/masters/${masterId}`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    // eslint-disable-next-line no-alert
    .then(() => {
      dispatch<any>(fetchMasterDetailsAsync(api, Number(masterId)));
      toast.success('Master updated successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error) => Errors.throwErrorMessage(error));
  }
};

export default updateMaster;
