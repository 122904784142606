import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { InvestorOrder } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export const FETCH_MASTER_INVESTORS_ORDERS = 'FETCH_MASTER_INVESTORS_ORDERS ';

export type FetchMasterInvestorsOrdersAction = {
  type: string;
  orders: Array<InvestorOrder>;
  paginationInfo: PaginationInfo;
}

export const fetchMasterInvestorsOrders = (orders: Array<InvestorOrder>, paginationInfo: PaginationInfo): FetchMasterInvestorsOrdersAction => {
  return {
    type: FETCH_MASTER_INVESTORS_ORDERS,
    orders,
    paginationInfo
  }
};

function fetchMasterInvestorsOrdersAsync(api: string, orderId: string, page: number) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/masters/orders/investors/${orderId}?page=${page}&limit=10`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => dispatch(fetchMasterInvestorsOrders(data.Data, data.PaginationInfo)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchMasterInvestorsOrdersAsync;