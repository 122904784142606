import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleLeft,
  faArrowLeft,
  faArrowsAltH,
  faArrowRight,
  faBars,
  faBell,
  faCalendarAlt,
  faCat,
  faChartArea,
  faCheckSquare,
  faCircle,
  faCircleNotch,
  faClock,
  faCog,
  faCogs,
  faDollarSign,
  faDownload,
  faEllipsisV,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faFileAlt,
  faFilter,
  faHistory,
  faLanguage,
  faLink,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faPencilAlt,
  faPlus,
  faPowerOff,
  faRedo,
  faSave, 
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faSortDown,
  faSquare,
  faSuitcase,
  faTable,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrash,
  faUnlink,
  faUser,
  faUsers,
  faLongArrowAltRight,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';

const defaultColor = '#8dabc4';

const DownloadIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faDownload}/>;
const LanguageIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faLanguage}/>;
const FilterIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faFilter}/>;
const DollarSignIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faDollarSign}/>;
const DropdownIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faSortDown}/>;
const FileAltIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faFileAlt}/>;
const PlusIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faPlus}/>;
const PencilIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faPencilAlt}/>;
const UnlinkIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faUnlink}/>;
const LinkIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faLink}/>;
const TrashIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faTrash}/>;
const PowerOnIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faPowerOff}/>;
const TimesCircleIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faTimesCircle}/>;
const OnIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faToggleOff}/>;
const OffIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faToggleOn}/>;
const CogIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faCog}/>;
const CogsIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} size={size} icon={faCogs}/>;
const HistoryIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faHistory}/>;
const RoundIcon = (color?: string) => <FontAwesomeIcon color={color || defaultColor} icon={faCircle}/>;
const SwitchIcon = (enabled: boolean) => <FontAwesomeIcon color={enabled ? 'green' : '#CC0000'} icon={enabled ? faToggleOn : faToggleOff}/>;
const RedoIcon = (color?: string, className?: string) => <FontAwesomeIcon color={color || defaultColor} className={className} icon={faRedo}/>;
const SaveIcon = (color?: string, className?: string) => <FontAwesomeIcon color={color || defaultColor} className={className} icon={faSave}/>;
const AlertIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon size={size} color={color} icon={faExclamationTriangle}/>;
const LoadingCat = (size?: SizeProp) => <FontAwesomeIcon icon={faCat} size={size} spin/>;
const LoadingSpinner = (size?: SizeProp) => <FontAwesomeIcon icon={faCircleNotch} size={size} spin/>;
const AngleDoubleDownIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleDoubleDown} size={size}/>;
const AngleDownIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleDown} size={size}/>;
const ArrowsAltH = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faArrowsAltH} size={size}/>;
const AngleLeftIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleLeft} size={size}/>;
const AngleRightIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleRight} size={size}/>;
const AngleDoubleUpIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleDoubleUp} size={size}/>;
const AngleUpIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faAngleUp} size={size}/>;
const ArrowUp = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faLongArrowAltUp} size={size}/>;
const ArrowDown = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faLongArrowAltDown} size={size}/>;
const ArrowRight = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faLongArrowAltRight} size={size}/>;
const ArrowAltCircleLeft = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faArrowAltCircleLeft} size={size}/>;
const CheckSquareIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faCheckSquare} size={size}/>;
const SquareIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSquare} size={size}/>;
const EyeIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faEye} size={size}/>;
const SearchIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSearch} size={size}/>;
const SignInIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSignInAlt} size={size} />;
const SignOutIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSignOutAlt} size={size} />;
const UserIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faUser} size={size} />;
const UsersIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faUsers} size={size} />;
const EllipsisIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faEllipsisV} size={size} />;
const ChartAreaIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faChartArea} size={size} />;
const ExchangeIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faExchangeAlt} size={size} />;
const SlidersIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSlidersH} size={size} />;
const SuitcaseIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faSuitcase} size={size} />;
const ClockIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faClock} size={size} />;
const BarsIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faBars} size={size} />;
const BellIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faBell} size={size} />;
const CircleIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faCircle} size={size} />;
const ArrowLeftIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faArrowLeft} size={size} />;
const ArrowRightIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faArrowRight} size={size} />;
const CalendarIcon = (color?: string, size?: SizeProp) => <FontAwesomeIcon color={color || defaultColor} icon={faCalendarAlt} size={size} />;
const TableIcon = <FontAwesomeIcon icon={faTable}/>;

const Icons = {
  DownloadIcon,
  LanguageIcon,
  FilterIcon,
  DollarSignIcon,
  PlusIcon,
  FileAltIcon,
  PencilIcon,
  UnlinkIcon,
  TrashIcon,
  CogIcon,
  CogsIcon,
  HistoryIcon,
  RoundIcon,
  SwitchIcon,
  OnIcon,
  OffIcon,
  PowerOnIcon,
  TimesCircleIcon,
  RedoIcon,
  SaveIcon,
  AlertIcon,
  LoadingCat,
  LoadingSpinner,
  AngleDoubleDownIcon,
  ArrowsAltH,
  AngleDownIcon,
  AngleDoubleUpIcon,
  AngleUpIcon,
  AngleLeftIcon,
  AngleRightIcon,
  ArrowDown,
  ArrowAltCircleLeft,
  ArrowUp,
  LinkIcon,
  TableIcon,
  CheckSquareIcon,
  SquareIcon,
  EyeIcon,
  SearchIcon,
  DropdownIcon,
  ArrowRight,
  SignInIcon,
  UserIcon,
  UsersIcon,
  EllipsisIcon,
  ChartAreaIcon,
  ExchangeIcon,
  SlidersIcon,
  SuitcaseIcon,
  ClockIcon,
  BarsIcon,
  BellIcon,
  SignOutIcon,
  CircleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon
};

export default Icons;
