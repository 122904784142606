export enum VisibilityToggle {
  Show = 'Show',
  Hide = 'Hide',
  Default = 'Default'
};

export enum VisibilityToggleAdmin {
  Show = 'Show',
  Hide = 'Hide'
};

export enum StateToggle {
  On = 'On',
  Off = 'Off',
  Default = 'Default'
}

export enum StateToggleAdmin {
  On = 'On',
  Off = 'Off'
}

export enum StateRequestToggle {
  On = 'On',
  Off = 'Off',
  Request = 'Request',
  Default = 'Default'
}

export enum StateRequestToggleAdmin {
  On = 'On',
  Off = 'Off',
  Request = 'Request'
}

export enum BooleanToggle {
  True = 'true',
  False = 'false'
}