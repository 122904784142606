import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';

export const FETCH_REQUESTS_COUNT = 'FETCH_REQUESTS_COUNT';

export type FetchRequestsCountAction = {
  type: string;
  count: number;
}

export const fetchRequestsCount = (count: number): FetchRequestsCountAction => {
  return {
    type: FETCH_REQUESTS_COUNT,
    count
  };
};

function fetchRequestsCountAsync(api: string) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/requests/count?statusFilter=new`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((count: any) => dispatch(fetchRequestsCount(count as number)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchRequestsCountAsync;
