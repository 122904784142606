import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { PaginationInfo } from '../../../types/sharedTypes';
import setLoadingStatus from '../../../redux/actions/setLoadingStatus';

export const FETCH_INVESTORS_REPORT = 'FETCH_INVESTORS_REPORT';

export type FetchInvestorsReportAction = {
  type: string;
  investorsReport: Array<any>;
  paginationInfo: PaginationInfo;
  
};

const fetchInvestorsReport = (investorsReport: Array<any>, paginationInfo: PaginationInfo): FetchInvestorsReportAction => {
  return {
    type: FETCH_INVESTORS_REPORT,
    investorsReport,
    paginationInfo,
  };
};

const fetchInvestorsReportAsync = (
  api: string,
  page?: number,
  limit?: number,
  sortField?: string,
  sortDirection?: string,
  filtered?: Array<any>
  ) => {
    let queryString = `?page=${page}`;

    if (limit) queryString += `&limit=${limit}`;
    if (sortField) queryString += `&sortField=${sortField}`;
    if (sortDirection) queryString += `&sortDirection=${sortDirection}`;
    // eslint-disable-next-line no-unused-expressions
    filtered?.forEach((filter: any): void => {
      if (filter.id === 'mtserver') queryString += `&investorMtServerFilter=${filter.value}`
      if (filter.id === 'mtlogin') queryString += `&investorMtLoginFilter=${filter.value}`
      if (filter.id === 'pammid') queryString += `&investorIdFilter=${filter.value}`
      if (filter.id === 'mmpammid') queryString += `&mmIdFilter=${filter.value}`
      if (filter.id === 'balancefrom') queryString += `&balanceFrom=${filter.value}`
      if (filter.id === 'balanceto') queryString += `&balanceTo=${filter.value}`
      if (filter.id === 'equityfrom') queryString += `&equityFrom=${filter.value}`
      if (filter.id === 'equityto') queryString += `&equityTo=${filter.value}`
      if (filter.id === 'investmentsfrom') queryString += `&investmentsFrom=${filter.value}`
      if (filter.id === 'investmentsto') queryString += `&investmentsTo=${filter.value}`
      if (filter.id === 'totalprofitfrom') queryString += `&totalProfitFrom=${filter.value}`
      if (filter.id === 'totalprofitto') queryString += `&totalProfitTo=${filter.value}`
      if (filter.id === 'createdfrom') queryString += `&createdFrom=${filter.value/1000}`
      if (filter.id === 'createdto') queryString += `&createdTo=${filter.value/1000}`
    })

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/investors/summary${queryString}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => dispatch(fetchInvestorsReport(data.Data, data.PaginationInfo)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchInvestorsReportAsync;
