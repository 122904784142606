import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import setLoadingStatus from '../setLoadingStatus';
import headersBuilder, { Method } from '../../../utils/request';
import { PaginationInfo } from '../../../types/sharedTypes';
import { areAllPropsEQFalse, convertAllPropsToTrue } from '../../../utils/object';

export const FETCH_REQUESTS = 'FETCH_REQUESTS';

export type FetchRequestsAction = {
  type: string;
  requests: Array<any>;
  paginationInfo: PaginationInfo;
}

export const fetchRequests = (requests: Array<any>, paginationInfo: PaginationInfo): FetchRequestsAction => {
  return {
    type: FETCH_REQUESTS,
    requests,
    paginationInfo
  };
};

function fetchRequestsAsync(
  api: string,
  createdFrom: Date,
  createdTo: Date,
  page?: number,
  limit?: number,
  typeFilters?: any,
  statusFilters?: any,
  idSearch?: string,
  mmChangeFeeAccountsRequestOff?: boolean
) {
  let query = '';
  let verifiedTypeFilters: Record<string, boolean>;
  let verifiedStatusFilters: Record<string, boolean>;

  query += `page=${page}&`;
  query += `limit=${limit}&`;

  if (areAllPropsEQFalse(typeFilters)) {
    verifiedTypeFilters = convertAllPropsToTrue(typeFilters);
  } else {
    verifiedTypeFilters = typeFilters;
  }

  Object.keys(verifiedTypeFilters).forEach((filter) => {
    if (verifiedTypeFilters[filter]) {
      query += `typeFilter=${filter.toLowerCase()}&`
    }
  });

  if (areAllPropsEQFalse(statusFilters)) {
    verifiedStatusFilters = convertAllPropsToTrue(statusFilters);
  } else {
    verifiedStatusFilters = statusFilters;
  }

  Object.keys(verifiedStatusFilters).forEach((filter) => {
    if (verifiedStatusFilters[filter]) {
      query += `statusFilter=${filter.toLowerCase()}&`
    }
  });

  query += `createdFrom=${Math.round(createdFrom.getTime() / 1000)}&`;
  query += `createdTo=${Math.round(createdTo.getTime() / 1000)}`;

  if (idSearch) query += `&query=${idSearch}`;

  if (mmChangeFeeAccountsRequestOff) {
    query = query.replace('typeFilter=changefeeaccountsinfo&', '');
  }

  return (dispatch: Dispatch) => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/requests?${query}&sortField=id&direction=desc`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => dispatch(fetchRequests(data.Data, data.PaginationInfo)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchRequestsAsync;
