import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useDispatch } from 'react-redux';
import { getInfoMastersThunkAC } from '../../redux/actions/master/fetchMasters';
import LocalStorageUtils from '../../utils/local-storage';

export const PrivateRoute = ({ component, ...rest }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoMastersThunkAC());
  }, []);

  const routeComponent = () => {

    if (rest.isAuthenticated) {
      return component
    }

    if (window.location.pathname !== '/login') {
    const accountType = LocalStorageUtils.getValueFromLocalStorage('accountType')
    LocalStorageUtils.putValueToLocalStorage('rememberPage', JSON.stringify({page: window.location.pathname, accountType: accountType || ''}));
    }
    
    return <Redirect to={{ pathname: '/login' }} />;

  }

  return <Route {...rest} render={routeComponent} />;
};
