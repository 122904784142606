import { Dispatch } from 'redux';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { IPaymentPeriod } from '../../../types/masterTypes';

export const FETCH_MASTER_PAYMENT_PERIODS = 'FETCH_MASTER_PAYMENT_PERIODS ';

export type FetchMasterPaymentPeriodsAction = {
  type: string;
  paymentPeriods: Array<IPaymentPeriod>;
}

export const fetchMasterPaymentPeriods = (paymentPeriods: Array<IPaymentPeriod>): FetchMasterPaymentPeriodsAction => {
  return {
    type: FETCH_MASTER_PAYMENT_PERIODS,
    paymentPeriods
  }
};

function fetchMasterPaymentPeriodsAsync(api: string, masterId: number) {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/masters/paymentPeriods/${masterId}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((paymentPeriods: any) => dispatch(fetchMasterPaymentPeriods(paymentPeriods)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
}

export default fetchMasterPaymentPeriodsAsync;
