import { Dispatch } from 'redux';
import Errors from '../../shared/validation/errors';
import headersBuilder, { Method } from '../../utils/request';
import { Log } from '../../types/journalTypes';
import setLoadingStatus from './setLoadingStatus';
import { PaginationInfo } from '../../types/sharedTypes';

export const FETCH_LOGS = 'FETCH_LOGS';

export type FetchLogsAction = {
  type: string;
  logs: Array<Log>;
  paginationInfo: PaginationInfo;
};

const fetchLogs = (logs: Array<Log>, paginationInfo: PaginationInfo): FetchLogsAction => {
  return {
    type: FETCH_LOGS,
    logs,
    paginationInfo
  };
};

const fetchLogsAsync = (api: string, filters: any, startDate: Date, endDate: Date, page?: number, limit?: number, search?: string) => {
  let query = '';

  const startDateTimestamp = startDate.getTime() / 1000;
  const endDateTimestamp = endDate.getTime() / 1000;

  Object.keys(filters).forEach((filter) => {
    if (filters[filter]) {
      query += `filter=${filter}&`
    }
  });

  query += `from=${startDateTimestamp}&`;
  query += `to=${endDateTimestamp}&`;
  
  if (search) query +=`query=${search}&`;

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/admins/operations?${query}page=${page}&limit=${limit}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((json: any) => dispatch(fetchLogs(json.Data, json.PaginationInfo)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchLogsAsync;