import { Dispatch } from 'redux';
import setLoadingStatus from '../../../redux/actions/setLoadingStatus';
import Errors from '../../../shared/validation/errors';
import { IMonitoringResponse } from '../../../types/maintenanceTypes';
import headersBuilder, { Method } from '../../../utils/request';

export const FETCH_MONITORING_ACTION = 'FETCH_MONITORING_ACTION';

export type MonitoringAction = {
  type: string;
  parameters: IMonitoringResponse;
};

const fetchMonitoringAction = (parameters: IMonitoringResponse): MonitoringAction => {
  return {
    type: FETCH_MONITORING_ACTION,
    parameters
  };
};

const fetchMonitoringAsync = (api: string) => {
    return (dispatch: Dispatch): void => {
      dispatch(setLoadingStatus(true));
      fetch(`${api}/maintenance/checkComponents`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((parameters: any) => {
        dispatch(fetchMonitoringAction(parameters));
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
    }
  }

export default fetchMonitoringAsync;
