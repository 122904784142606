export const SET_OPS_MENU_STATUS = 'SET_OPS_MENU_STATUS ';
export const SET_LINKED_ACCOUNTS_MENU_STATUS = 'SET_LINKED_ACCOUNTS_MENU_STATUS';
export const SET_LANGUAGES_MENU_STATUS = 'SET_LANGUAGES_MENU_STATUS';
export const SET_CONNECT_TO_MASTER_CONFIRMATION_STATUS = 'SET_CONNECT_TO_MASTER_CONFIRMATION_STATUS';
export const SET_DISCONNECT_FROM_MASTER_CONFIRMATION_STATUS = 'SET_DISCONNECT_FROM_MASTER_CONFIRMATION_STATUS';

export type SetMobileMenusStatus = {
  type: string;
  status: boolean;
}

export const setOpsMenuStatus = (status: boolean): SetMobileMenusStatus => {
  return {
    type: SET_OPS_MENU_STATUS,
    status
  }
};

export const setLinkedAccountsMenuStatus = (status: boolean): SetMobileMenusStatus => {
  return {
    type: SET_LINKED_ACCOUNTS_MENU_STATUS,
    status
  }
};

export const setLanguagesMenuStatus = (status: boolean): SetMobileMenusStatus => {
  return {
    type: SET_LANGUAGES_MENU_STATUS,
    status
  }
};

export const setConnectToMasterConfirmationStatus = (status: boolean): SetMobileMenusStatus => {
  return {
    type: SET_CONNECT_TO_MASTER_CONFIRMATION_STATUS,
    status
  }
};

export const setDisconnectFromMasterConfirmationStatus = (status: boolean): SetMobileMenusStatus => {
  return {
    type: SET_DISCONNECT_FROM_MASTER_CONFIRMATION_STATUS,
    status
  }
};
