import { FETCH_FEE_ACCOUNTS, FetchFeeAccountsAction } from '../actions/feeAccounts/fetchFeeAccounts';
import { PaginationInfo } from '../../types/sharedTypes';
import { FeeAccount } from '../../types/feeAccountTypes';

export interface FeeAccountsState {
  feeAccounts: Array<FeeAccount>;
  paginationInfo: PaginationInfo;
}

const initialState: FeeAccountsState = {
  feeAccounts: [],
  paginationInfo: new PaginationInfo()
};

const feeAccountsReducer = (state: FeeAccountsState = initialState, action: FetchFeeAccountsAction): FeeAccountsState => {
  switch (action.type) {
    case FETCH_FEE_ACCOUNTS:
      return {
        ...state,
        feeAccounts: action.feeAccounts,
        paginationInfo: action.paginationInfo
      };
    default:
      return state;
  }
}

export default feeAccountsReducer;
