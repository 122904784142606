import { CREATE_USER, CreateUserAction } from '../../actions/shared/createUser';

// TODO: Find out how to type 'createdUser'
export interface CreateUserState {
  createdUser: any;
}

const initialState: CreateUserState = {
  createdUser: {}
};

const createUserReducer = (state: CreateUserState = initialState, action: CreateUserAction): CreateUserState => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        createdUser: action.createdUser
      };
    default:
      return state;
  }
}

export default createUserReducer;