import { FETCH_VOLUME, FetchVolumeAction } from '../../actions/master/fetchVolume';
import { Summary } from '../../../types/sharedTypes';

export interface VolumeState {
  volumePerPeriod: Array<Summary>;
}

const initialState: VolumeState = {
  volumePerPeriod: [],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const volumeReducer = (state: VolumeState = initialState, action: FetchVolumeAction): VolumeState => {
  switch (action.type) {
    case FETCH_VOLUME:
      return {
        ...state,
        volumePerPeriod: action.volumeFortnight
      };
    default:
      return state;
  }
}

export default volumeReducer;