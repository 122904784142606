export enum RequestType {
  CreateInvestor = 'CreateInvestor',
  CreateMm = 'CreateMm',
  Attach = 'Attach',
  Detach = 'Detach',
  ChangeFeeAccountsInfo = 'ChangeFeeAccountsInfo',
  ChangeBalance = 'ChangeBalance',
  ChangeCredit = 'ChangeCredit',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export enum RequestStatus {
  Approved = 'Approved',
  New = 'New',
  Planned = 'Planned',
  Executing = 'Executing',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Error = 'Error'
}

export class Request {
  MmId = 0;
  MmName = '';
  MmEmail = '';
  MmLogin = 0;
  MmServer = '';
  Id = 0;
  Type = RequestType.Attach;
  Status = RequestStatus.New;
  TypeCode = 0;
  StatusCode = 0;
  Comment = 0;
  AdminComment = 0;
  SystemComment = 0;
  Info = 0;
  CreatedById = 0;
  CreatedByName = '';
  CreatedByEmail = '';
  AccountId = 0;
  AccountName = '';
  AccountEmail = '';
  CreatedAt = 0;
  ExecutedAt = 0;
  CreatedAtStr = '';
  ExecutedAtStr = '';
}

export enum RequestPeriod {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Year = 'Year'
}

export enum ChangeBalanceMethod {
  FreeMargin = 'FreeMargin',
  PartialClose = 'PartialClose'
}

export enum RequestSchedule {
  Instant = 'Instant',
  Planned = 'Planned'
}
