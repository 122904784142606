import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../../actions/setLoadingStatus';
import { Server } from '../../../types/sharedTypes';

export const FETCH_SERVERS = 'FETCH_SERVERS';

export type FetchServersAction = {
  type: string;
  servers: Array<Server>;
};

const fetchServers = (servers: Array<Server>): FetchServersAction => {
  return {
    type: FETCH_SERVERS,
    servers
  };
};

const fetchServersAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/mtServers`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((servers: any) => dispatch(fetchServers(servers)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchServersAsync;
