import { Schema } from 'yup';

function validateSchema<T, S>(schema: Schema<T>, state: S, valueKey?: keyof S): any {
  if (valueKey) {
    try {
      schema.validateSync(state[valueKey]);
    } catch (e) {
      return e;
    }
  } else {
    try {
      schema.validateSync(state);
    } catch (e) {
      return e;
    }
  }

  return false;
}

const InputUtils = {
  validateSchema
};

export default InputUtils;
