import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IOrderReopen } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';


export const FETCH_ORDERS_REOPEN = 'FETCH_ORDERS_REOPEN';

export type OrderReopenAction = {
  type: string;
  orderReopen: IOrderReopen;
};

const fetchOrderReopen = (orderReopen: IOrderReopen): OrderReopenAction => {
  return {
    type: FETCH_ORDERS_REOPEN,
    orderReopen
  };
};

const fetchOrderReopenAsync = (api: string, request: IOrderReopen) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/orders/reopen`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((orderReopen: any) => {
      dispatch(fetchOrderReopen(orderReopen));
      toast.success('Load Order successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchOrderReopenAsync;
