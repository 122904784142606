const getCurrencySymbol = (currency: any , currenciesConfig?: Record<string, string>): string => {
  // switch (currency) {
  //   case 'USD':
  //     return '$';
  //   case 'EUR':
  //     return '€';
  //   case 'GBP':
  //     return '£';
  //   case 'CHF':
  //     return '₣';
  //   case 'JPY':
  //     return '¥';
  //   default:
  //     return '';
  // }
  let result = '';
  if (currenciesConfig) {
    const currenciesConfigArray = Object.keys(currenciesConfig)
    
    if (currenciesConfigArray.includes(currency)) {
       result = currenciesConfig[currency];
    }

    else result = currency
   
  }
  return result;
};

export default getCurrencySymbol;
