import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { MetaTraderServerSettings, EditMetaTraderServerRequest } from '../../../types/settingsTypes';
import setLoadingStatus, { checkMtServersUpdate } from '../setLoadingStatus';

export const FETCH_CONNECTED_MT_SERVERS = 'FETCH_CONNECTED_MT_SERVERS';
export const FETCH_MT_SERVERS_DISABLED = 'FETCH_MT_SERVERS_DISABLED';
export const FETCH_MT_SERVERS_ENABLED = 'FETCH_MT_SERVERS_ENABLED';

export type FetchMtServersSettingsAction = {
  type: string;
  settings: Array<MetaTraderServerSettings>;
};

const fetchMtServersSettings = (settings: Array<MetaTraderServerSettings>): FetchMtServersSettingsAction => {
  return {
    type: FETCH_CONNECTED_MT_SERVERS,
    settings
  };
};

const fetchMtServersSettingsDisabled = (settings: Array<MetaTraderServerSettings>): FetchMtServersSettingsAction => {
  return {
    type: FETCH_MT_SERVERS_DISABLED,
    settings
  };
};

const fetchMtServersSettingsEnabled = (settings: Array<MetaTraderServerSettings>): FetchMtServersSettingsAction => {
  return {
    type: FETCH_MT_SERVERS_ENABLED,
    settings
  };
};

const fetchMtServersSettingsAsync = (api: string, filter?: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
      if (!filter) {
    fetch(`${api}/settings/mtServers`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((addresses: any) => dispatch(fetchMtServersSettings(addresses)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
      } else {
        fetch(`${api}/settings/mtServers?filter=${filter || ''}`, headersBuilder(Method.GET))
          .then((response: Response) => Errors.checkResponseForErrors(response, api))
          .then((addresses: any) => {
            if (filter === 'disabled') {
              dispatch(fetchMtServersSettingsDisabled(addresses))
            } 
            if (filter === 'enabled') {
              dispatch(fetchMtServersSettingsEnabled(addresses))
            }
            if (filter === undefined) {
              dispatch(fetchMtServersSettings(addresses))
            }
          })
          .catch((error: Error) => Errors.throwErrorMessage(error))
          .finally(() => dispatch(setLoadingStatus(false)));
      }
  }
};

export const editServerSettingsAsync = (api: string, request: EditMetaTraderServerRequest) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/mtServers`, headersBuilder(Method.POST, request))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then(() => {
        toast.success('Connected Meta Trader servers settings edited successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .then(() => dispatch(checkMtServersUpdate(true)))
      .catch((error: Error) => Errors.throwErrorMessage(error))
  }
};

export default fetchMtServersSettingsAsync;
