import { combineReducers } from 'redux';
import configReducer from './configReducer';
import authReducer from './authReducer';
import investorsReducer from './investor/investorsReducer';
import investorReducer from './investor/investorReducer';
import masterReducer from './master/masterReducer';
import ordersReducer from './shared/ordersReducer';
import balanceOperationsReducer from './shared/balanceOperationsReducer';
import mastersReducer from './master/mastersReducer';
import profitReducer from './shared/profitReducer';
import volumeReducer from './master/volumeReducer';
import generalInfoReducer from './shared/generalInfoReducer';
import createUserReducer from './shared/createUserReducer';
import settingsReducer from './settings/settingsReducer';
import adminsReducer from './admin/adminsReducer';
import adminReducer from './admin/adminReducer';
import loadingStatusReducer from './loadingStatusReducer';
import journalReducer from './journalReducer';
import summaryReducer from './shared/summaryReducer';
import requestsReducer from './requests/requestsReducer';
import feeAccountsReducer from './feeAccountsReducer';
import mobileMenusStatusReducer from './mobileMenusStatusReducer';
import masterInvestorsOrdersReducer from './master/masterInvestorsOrders';
import reportReducer from './maintenance/reportReducer'

const rootReducer = combineReducers({
  configReducer,
  authReducer,
  investorReducer,
  masterReducer,
  investorsReducer,
  ordersReducer,
  balanceOperationsReducer,
  mastersReducer,
  profitReducer,
  volumeReducer,
  generalInfoReducer,
  createUserReducer,
  settingsReducer,
  adminsReducer,
  adminReducer,
  loadingStatusReducer,
  journalReducer,
  summaryReducer,
  requestsReducer,
  feeAccountsReducer,
  mobileMenusStatusReducer,
  masterInvestorsOrdersReducer,
  reportReducer,
});

export default rootReducer;

export type PAMMState = ReturnType<typeof rootReducer>;
