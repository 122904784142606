import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IPositionCheckFix } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';


export const FETCH_POSITION_CHECK_FIX = 'FETCH_POSITION_CHECK_FIX';

export type PositionCheckFixAction = {
  type: string;
  positionCheckFix: IPositionCheckFix;
};

const fetchIPositionCheckFix = (positionCheckFix: IPositionCheckFix): PositionCheckFixAction => {
  return {
    type: FETCH_POSITION_CHECK_FIX,
    positionCheckFix
  };
};

const fetchPositionCheckFixAsync = (api: string, request: IPositionCheckFix, parameter: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/${parameter}/positions`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((positionCheckFix: any) => {
      dispatch(fetchIPositionCheckFix(positionCheckFix));
      toast.success('Load Orders successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchPositionCheckFixAsync;
