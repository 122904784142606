import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { CreateInvestor, Investor } from '../../../types/investorTypes';
import { CreateMaster, Master } from '../../../types/masterTypes';
import { CreateAdmin, Admin } from '../../../types/adminTypes';
import { UserType } from '../../../types/sharedTypes';

export const CREATE_USER = 'CREATE_USER';

type CreateUser = CreateInvestor | CreateMaster | CreateAdmin;
export type CreatedUser = Investor | Master | Admin;

export type CreateUserAction = {
  type: string;
  createdUser: CreatedUser;
};

const createUser = (createdUser: CreatedUser): CreateUserAction => {
  return {
    type: CREATE_USER,
    createdUser
  };
};

const createUserAsync = (api: string, request: CreateUser, userType: UserType) => {
  const endpoint = userType.toLowerCase();

  return (dispatch: Dispatch): void => {
    fetch(`${api}/${endpoint}s/create`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((createdUser: any) => {
      dispatch(createUser(createdUser));
      toast.success(`${userType.charAt(0).toUpperCase() + userType.slice(1)} created successfully!`, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  };
};

export default createUserAsync;
