export interface User {
  ViewOnly: boolean;
  TokenType: string;
  Token: string;
  ExpiresIn: number;
  Id: number;
  AccountType: AccountType;
  LinkToken: string;
  Name: string;
  Username: string;
  AccountOptions: any;
  AccountInfo: any;
}

export enum AccountType {
  Admin = 'admin',
  Guest = 'guest',
  Investor = 'investor',
  Master = 'master'
}