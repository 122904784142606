import { FETCH_MASTERS, FetchMastersAction, FETCH_MASTERS_DEFAULT, FetchMastersDefaultAction } from '../../actions/master/fetchMasters';
import { Master } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface MastersState {
  masters: Array<Master>;
  mastersDefault: Array<Master>;
  paginationInfo: PaginationInfo;
}

const initialState: MastersState = {
  masters: [],
  mastersDefault: [],
  paginationInfo: new PaginationInfo(),
};

type FetchMasters = FetchMastersAction & FetchMastersDefaultAction;

const mastersReducer = (state: MastersState = initialState, action: FetchMasters): MastersState => {
  switch (action.type) {
    case FETCH_MASTERS:
      return {
        ...state,
        masters: action.masters,
        paginationInfo: action.paginationInfo
      };
    case FETCH_MASTERS_DEFAULT:
      return {
        ...state,
        mastersDefault: action.mastersDefault
      }
    default:
      return state;
  }
}

export default mastersReducer;
