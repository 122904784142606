import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import { IInvestorsMassAttach, IInvestorsMassDetach } from '../../../types/investorTypes';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_MASS_ATTACH = 'FETCH_MASS_ATTACH';

export type MassAtachAction = {
  type: string;
  massAttach: IInvestorsMassAttach;
};

const fetchMassAttach = (massAttach: IInvestorsMassAttach): MassAtachAction => {
  return {
    type: FETCH_MASS_ATTACH,
    massAttach
  };
};

const massAttachInvestors = (api: string, request: IInvestorsMassAttach) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/investors/attach`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    .then((massAttach: any) => {
      dispatch(fetchMassAttach(massAttach));
        toast.success('Request to server is sent successfully, check details of connection in response', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    })
    .catch((error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const massDetachInvestors = (api: string, request: IInvestorsMassDetach) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/investors/detach`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    .then((massAttach: any) => {
      dispatch(fetchMassAttach(massAttach));
        toast.success('Request to server is sent successfully, check details of connection in response', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    })
    .catch((error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};




export default massAttachInvestors;
