import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { ServerSettingsResponse, ServerSettingsRequest } from '../../../types/settingsTypes';
import setLoadingStatus from '../../actions/setLoadingStatus';

export const FETCH_SERVER_SETTINGS = 'FETCH_SERVER_SETTINGS';

export type FetchServerSettingsAction = {
  type: string;
  settings: ServerSettingsResponse;
};

const fetchServerSettings = (settings: ServerSettingsResponse): FetchServerSettingsAction => {
  return {
    type: FETCH_SERVER_SETTINGS,
    settings
  };
};

const fetchServerSettingsAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/common`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((addresses: any) => dispatch(fetchServerSettings(addresses)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const editServerSettingsAsync = (api: string, request: ServerSettingsRequest) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/common`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => {
      dispatch(fetchServerSettings(settings));
      toast.success('Server settings edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchServerSettingsAsync;
