import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { IInvestorsCheck } from '../../../types/maintenanceTypes';
import setLoadingStatus from '../setLoadingStatus';


export const FETCH_INVESTORS_CHECK_PARAMETERS = 'FETCH_INVESTORS_CHECK_PARAMETERS';

export type InvestorsParametersAction = {
  type: string;
  parametersInvestors: IInvestorsCheck;
};

const fetchInvestorsParameters = (parametersInvestors: IInvestorsCheck): InvestorsParametersAction => {
  return {
    type: FETCH_INVESTORS_CHECK_PARAMETERS,
    parametersInvestors
  };
};

const fetchInvestorsParametersAsync = (api: string, request: IInvestorsCheck) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/maintenance/its/checkInvestors`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((parametersInvestors: any) => {
      dispatch(fetchInvestorsParameters(parametersInvestors));
      toast.success('Load Orders successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  };
};

export default fetchInvestorsParametersAsync;
