import { FETCH_OPEN_ORDERS, FETCH_CLOSED_ORDERS, FetchOrdersAction } from '../../actions/shared/fetchOrders';
import { PaginationInfo } from '../../../types/sharedTypes';

// TODO: properly type 'orders'
export interface OrdersState {
  openOrders: {
    orders: Array<any>;
    paginationInfo: PaginationInfo;
  };
  closedOrders: {
    orders: Array<any>;
    paginationInfo: PaginationInfo;
  };
}

const initialState: OrdersState = {
  openOrders: {
    orders: [],
    paginationInfo: new PaginationInfo()
  },
  closedOrders: {
    orders: [],
    paginationInfo: new PaginationInfo()
  }
};

const ordersReducer = (state: OrdersState = initialState, action: FetchOrdersAction): OrdersState => {
  switch (action.type) {
    case FETCH_OPEN_ORDERS:
      return {
        ...state,
        openOrders: {
          orders: action.orders,
          paginationInfo: action.paginationInfo
        }
      };
    case FETCH_CLOSED_ORDERS:
      return {
        ...state,
        closedOrders: {
          orders: action.orders,
          paginationInfo: action.paginationInfo
        }
      };
    default:
      return state;
  }
}

export default ordersReducer;