import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { OrderType } from '../../../types/sharedTypes';
import { setLoadingStatusClosedOrders, setLoadingStatusOpenOrders } from '../setLoadingStatus';

export const FETCH_OPEN_ORDERS = 'FETCH_OPEN_ORDERS';
export const FETCH_CLOSED_ORDERS = 'FETCH_CLOSED_ORDERS';

export type FetchOrdersAction = {
  type: string;
  orders: any;
  paginationInfo: any;
}

const fetchOpenOrders = (orders: any, paginationInfo: any): FetchOrdersAction => {
  return {
    type: FETCH_OPEN_ORDERS,
    orders,
    paginationInfo
  };
};

const fetchClosedOrders = (orders: any, paginationInfo: any): FetchOrdersAction => {
  return {
    type: FETCH_CLOSED_ORDERS,
    orders,
    paginationInfo
  };
};

const fetchOrdersAsync = (
  api: string,
  source: string,
  type: OrderType,
  id?: number,
  page?: number,
  query?: string,
  limit?: number,
  sortField?: string,
  direction?: string,
  mtServerGuid?: string | undefined,
): any => {
  let queryString = `?page=${page}`;
  let delimiter: string;

  if (limit) queryString += `&limit=${limit}`;
  if (sortField) queryString += `&sortField=${sortField}`;
  if (direction) queryString += `&direction=${direction}`;
  if (query) queryString += `&query=${query}`;
  if (mtServerGuid) queryString +=`&mtServerGuid=${mtServerGuid}`;

  if (id)
    delimiter = `${type === OrderType.Open ? OrderType.Open : OrderType.Closed}/${id}`;
  else
    delimiter = `${type === OrderType.Open ? OrderType.Open : OrderType.Closed}`

  return (dispatch: Dispatch): void => {
    if (type === OrderType.Open) {
      dispatch(setLoadingStatusOpenOrders(true));
    } else {
      dispatch(setLoadingStatusClosedOrders(true));
    }

    fetch(`${api}/${source === 'investors' ? 'investors' : 'masters'}/orders/${delimiter}${queryString}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => {
      if (type === OrderType.Open) {
        return dispatch(fetchOpenOrders(data.Data, data.PaginationInfo));
      }

      return dispatch(fetchClosedOrders(data.Data, data.PaginationInfo))
    })
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => {
      if (type === OrderType.Open) {
        dispatch(setLoadingStatusOpenOrders(false));
      } else {
        dispatch(setLoadingStatusClosedOrders(false));
      }
    })
  }
};


export default fetchOrdersAsync;
