import { FETCH_ADMINS, FetchAdminsAction } from '../../actions/admin/fetchAdmins';
import { Admin } from '../../../types/adminTypes';

export interface AdminsState {
  admins: Array<Admin>;
}

const initialState: AdminsState = {
  admins: [],
};

const adminsReducer = (state: AdminsState = initialState, action: FetchAdminsAction): AdminsState => {
  switch (action.type) {
    case FETCH_ADMINS:
      return {
        admins: action.admins,
      };
    default:
      return state;
  }
}

export default adminsReducer;
