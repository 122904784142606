/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icons from '../../icons/icons';
import { setLinkedAccountsMenuStatus } from '../../redux/actions/setMobileMenusStatus';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import LocalStorageUtils from '../../utils/local-storage';

const LinkedAccountsMenuMobile: React.FC<any> = ({
  menuHeight = 0,
  onSwipe,
  onCancel
}): JSX.Element => {
  const dispatch = useDispatch();

  const style = {
    width: '100%',
    height: menuHeight,
    position: 'fixed',
    zIndex: 9999,
    bottom: '-5rem',
    backgroundColor: 'white',
    transition: '0.5s',
    padding: '1.5rem',
    fontSize: '1.3rem'
  } as React.CSSProperties;

  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const linkedAccounts: Array<Investor> = useSelector((state: PAMMState) => state.investorsReducer.investors);
  const [selectedLinkedAccountIndex, setSelectedLinkedAccountIndex] = React.useState<number>(investor.Id);

  // TODO
  // React.useEffect(() => {
  //   if (investor.LinkToken)
  //     dispatch(fetchInvestorsAsync(api, investor.LinkToken, undefined, undefined, 1));
  // }, [dispatch, api, investor.Id]);

  React.useEffect(() => {
    setSelectedLinkedAccountIndex(linkedAccounts.findIndex((account: Investor) => account?.Id === investor?.Id));
  }, [investor, linkedAccounts]);

  const handleSelectLinkedAccount = (id: number): void => {
    // dispatch(fetchInvestorDetailsAsync(api, id));
    dispatch(setLinkedAccountsMenuStatus(false));
    // TODO
    // dispatch(
    //   fetchOrdersAsync(
    //     api,
    //     'investors',
    //     OrderType.Open,
    //     id,
    //     undefined,
    //     undefined,
    //     20,
    //     'mtorderid',
    //     'asc'
    //   )
    // );
    LocalStorageUtils.putValueToLocalStorage('linkedIdMobile', id.toString());
  };

  const btnStyle = {
    backgroundColor: 'white',
    color: 'inherit',
    border: 'none',
    fontSize: '1.3rem',
    padding: 0,
    margin: 0,
    width: '100%'
  } as React.CSSProperties;

  const linkedAccountsLinks = linkedAccounts.map((linkedAccount: any, index: number) => {
    if (linkedAccount?.Id !== investor.Id) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <>
          <Row>
            <Button
              // TODO
              // key={`${linkedAccount?.Id}`}
              onClick={() => handleSelectLinkedAccount(linkedAccount?.Id)}
              style={btnStyle}
            >
              <div className="row flex-nowrap align-items-center">
                <span className="mx-3">
                  {Icons.CircleIcon('lightgrey', '2x')}
                </span>
                <span className="mb-1 mr-3 ellipsis-style">
                  {linkedAccount?.Name}
                </span>
              </div>
            </Button>
          </Row>

          {index !== linkedAccounts?.length - 1 ? <hr /> : null}
        </>
      );
    }

    return true;
  })

  return (
    <div
      id="linked-accounts-menu"
      style={style}
      onPointerMove={(event) => onSwipe(event)}
      onPointerCancel={() => onCancel('LinkedAccountsMenu')}
    >
      {linkedAccountsLinks}
    </div>
  );
};

export default LinkedAccountsMenuMobile;
