import { Investor } from '../types/investorTypes';

export function abbreviatedNumber(value: number): string {
  if (value >= 1_000_000 && value < 1_000_000_000) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return `${spaceSeparatedThousands(+(value / 1_000_000).toFixed(2))} mln`;
  } 
  if (value >= 1_000_000_000) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return `${spaceSeparatedThousands(+(value / 1_000_000_000).toFixed(2))} bln`;
  }

  return value.toString();
}

export function spaceSeparatedThousands(value: number, noDecimals?: boolean, masterDetailsBox?: boolean): string {
  if (Math.abs(value) >= 1_000_000 && masterDetailsBox) {
    return abbreviatedNumber(Math.abs(value));
  }

  if (!value) return '0';

  if (Number.isNaN(value % 10)) {
    return '-';
  }

  const nStr = value.toString();
  const x = nStr.split('.');
  let x1 = x[0];

  // Change for showing either a dot or a comma
  const x2 = x.length > 1 ? `.${  x[1]}` : '.00';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return noDecimals ? x1 : (x1 + x2);
}

export function getEquityOverInvestments(investor: Investor): number {
  if (!investor?.Investments) {
      return 0;
  }

  return Number(((investor.Equity - investor.Investments) / Math.abs(investor.Investments) * 100).toFixed(2));
}

export const getSign = (value: number): string => {
  if (value > 0) {
    return '+';
  } if (value < 0) {
    return '-';
  }

  return '';
};
