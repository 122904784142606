import { FETCH_BALANCE_OPERATIONS, FetchBalanceOperationsAction } from '../../actions/shared/fetchBalanceOperations';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface BalanceOperationsState {
  balanceOperations: {
    operations: Array<any>;
    paginationInfo: PaginationInfo;
    totalAccruedToBalance: number;
    totalAccruedToCredit: number;
  };
}

const initialState: BalanceOperationsState = {
  balanceOperations: {
    operations: [],
    paginationInfo: new PaginationInfo(),
    totalAccruedToBalance: 0,
    totalAccruedToCredit: 0
  }
};

const balanceOperationsReducer = (state: BalanceOperationsState = initialState, action: FetchBalanceOperationsAction): BalanceOperationsState => {
  switch (action.type) {
    case FETCH_BALANCE_OPERATIONS:
      return {
        ...state,
        balanceOperations: {
          operations: action.balanceOperations.BalanceOperations,
          paginationInfo: action.paginationInfo,
          totalAccruedToBalance: action.balanceOperations.TotalAccruedToBalance,
          totalAccruedToCredit: action.balanceOperations.TotalAccruedToCredit
        }
      };
    default:
      return state;
  }
}

export default balanceOperationsReducer;
