import { FETCH_LOGS, FetchLogsAction } from '../actions/fetchLogs';
import { Log } from '../../types/journalTypes';
import { PaginationInfo } from '../../types/sharedTypes';

export interface JournalState {
  logs: Array<Log>;
  paginationInfo: PaginationInfo;
}

const initialState: JournalState = {
  logs: [],
  paginationInfo: new PaginationInfo()
};

const JournalReducer = (state: JournalState = initialState, action: FetchLogsAction): JournalState => {
  switch (action.type) {
    case FETCH_LOGS:
      return {
        ...state,
        logs: action.logs,
        paginationInfo: action.paginationInfo
      };
    default:
      return state;
  }
}

export default JournalReducer;
