import {
  FETCH_INVESTOR_DETAILS,
  FetchInvestorDetailsAction,
  FETCH_INVESTOR_DETAILS_FALSE,
  FETCH_INVESTOR_DETAILS_ATTACH_DETACH
} from '../../actions/investor/fetchInvestorDetails';
import { Investor } from '../../../types/investorTypes';
import { RESET_DETAIL_INVESTOR } from '../../actions/investor/investorDetail';

export interface InvestorDetailsState {
  investor: Investor;
  investorFalse: Investor;
  investorAttachDetachRequests: Investor;
}

const initialState: InvestorDetailsState = {
  investor: new Investor(),
  investorFalse: new Investor(),
  investorAttachDetachRequests: new Investor()
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const investorReducer = (
  state: InvestorDetailsState = initialState,
  action: FetchInvestorDetailsAction
): InvestorDetailsState => {
  switch (action.type) {
    case FETCH_INVESTOR_DETAILS:
      return {
        ...state,
        investor: action.investorDetails
      };

    case FETCH_INVESTOR_DETAILS_FALSE:
      return {
        ...state,
        investorFalse: action.investorDetails
      };
    
    case FETCH_INVESTOR_DETAILS_ATTACH_DETACH:
      return {
        ...state,
        investorAttachDetachRequests: action.investorDetails
      }

    case RESET_DETAIL_INVESTOR:
      return {
        ...state,
        investor: new Investor()
      };
    default:
      return state;
  }
};

export default investorReducer;
