import { CSSProperties } from 'react';

export const mobileBodyWithNavbar = {
  // width: '83vw',
  paddingTop: '3.5rem',
  marginBottom: '10rem'
} as CSSProperties;

export const mobileBodyWithEllipsis = {
  ...mobileBodyWithNavbar,
  paddingTop: '1.2rem'
} as CSSProperties;

export const shadowBox = {
  boxShadow: '0px 0px 10px 2px lightgrey',
  backgroundColor: 'white',
  margin: '1rem 1rem 0 1rem',
  padding: 0
} as CSSProperties;

export const pageButton = {
  borderRadius: '50%',
  height: '1.5rem',
  width: '1.5rem',
  backgroundColor: 'white',
  border: 'none',
  padding: 0,
  fontSize: '1rem',
  marginLeft: '1rem'
} as CSSProperties;

export const directionSwitchStyle = {
  backgroundColor: '#eee',
  height: 25,
  border: 0,
  borderRadius: 15,
  marginLeft: 5,
  marginTop: 7,
  padding: 0,
  paddingLeft: 10,
  paddingRight: 10
} as CSSProperties;

export const sortBySelectStyle = {
  width: 'auto',
  backgroundColor: '#eee',
  border: 0,
  borderRadius: 15,
  MozAppearance: 'none',
  WebkitAppearance: 'none',
  height: 25,
  fontSize: 12,
  marginLeft: '1rem',
  marginTop: 2
} as CSSProperties;
