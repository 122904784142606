import { FETCH_REQUESTS, FetchRequestsAction } from '../../actions/requests/fetchRequests';
import { FETCH_REQUESTS_COUNT, FetchRequestsCountAction } from '../../actions/requests/fetchRequestsCount';
import { PaginationInfo } from '../../../types/sharedTypes';

export interface RequestsState {
  requests: Array<any>;
  paginationInfo: PaginationInfo;
  count: number;
}

const initialState: RequestsState = {
  requests: [],
  paginationInfo: new PaginationInfo(),
  count: 0
};

type RequestsAction = FetchRequestsAction & FetchRequestsCountAction;

const requestsReducer = (state: RequestsState = initialState, action: RequestsAction): RequestsState => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.requests,
        paginationInfo: action.paginationInfo
      };
    case FETCH_REQUESTS_COUNT:
      return {
        ...state,
        count: action.count
      }
    default:
      return state;
  }
}

export default requestsReducer;
