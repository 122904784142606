import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../../actions/setLoadingStatus';
import { EditCallbackAddressesRequest, EditCallbackAddressesType } from '../../../types/settingsTypes';

export const FETCH_CALLBACK_ADDRESSES = 'FETCH_CALLBACK_ADDRESSES';

export type FetchCallbackAddressesAction = {
  type: string;
  addresses: string[];
};

const fetchCallbackAddresses = (addresses: string[]): FetchCallbackAddressesAction => {
  return {
    type: FETCH_CALLBACK_ADDRESSES,
    addresses
  };
};

const fetchCallbackAddressesAsync = (api: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/settings/callbacks`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((addresses: any) => dispatch(fetchCallbackAddresses(addresses)))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const editFetchCallbackAddressesAsync = (api: string, request: any) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/callbacks`, headersBuilder(Method.POST, request))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((settings: any) => {
      dispatch(fetchCallbackAddresses(settings));
      toast.success('Callback addresses edited successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export const editCallbackAddresses = (api: string, request: EditCallbackAddressesRequest, requestType: EditCallbackAddressesType) => {
  return (dispatch: Dispatch): void => {
    fetch(`${api}/settings/callbacks`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    // eslint-disable-next-line no-alert
    .then(() => {
      let successMessage = '';

      switch (requestType) {
        case EditCallbackAddressesType.Add:
          successMessage = 'Callback address added successfully!';
          break;
        case EditCallbackAddressesType.Delete:
          successMessage = 'Callback address deleted successfully!';
          break;
        case EditCallbackAddressesType.Edit:
          successMessage = 'Callback addresses edited successfully!';
          break;
        default:
          break;
      }

      toast.success(successMessage, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      dispatch(fetchCallbackAddressesAsync(api) as any);
    })
    .catch((error) => Errors.throwErrorMessage(error));
  }
};

export default fetchCallbackAddressesAsync;
